<script setup>

defineProps({
    sm: Boolean, //icon size 24
    md: Boolean, //icon size 32
    normal: Boolean, //icon size 40
    lg: Boolean, //icon size 48
    xl: Boolean, //icon size 56
    xxl: Boolean, //icon size 64
    email: String,
    showLocation: Boolean,
    date: String,
    hideName: Boolean,
    comment: Object,
    user: Object,
    profilePhoto: String,
    passedAwayOverlay: Boolean,
});

const emit = defineEmits();

const handleClickViewMoreImages = (comment) => {
    emit('onClickViewMoreImages', comment);
};

const avatarImageErrorFallback = (event) => {
    event.target.src = '/storage/icons/user-01.svg';
}

</script>

<template>
    <div class="flex flex-row justify-start items-start"
         :class="{
                    'gap-1': sm,
                    'gap-2': md || normal,
                    'gap-3': lg,
                    'gap-4': xl,
                    'gap-6': xxl,
                 }"
    >
        <img class="rounded-full bg-gray-100 mr-2 object-cover" :src="user?.profile_photo_url ? user?.profile_photo_url
                                                                                              : user?.remembrance_photo_url ? user?.remembrance_photo_url
                                                                                              : profilePhoto ? profilePhoto
                                                                                              : '/storage/icons/user-01.svg'"
             :class="[sm ? ['w-6','h-6'] : ''
            ,md ? ['w-8','h-8'] : ''
            ,normal ? ['w-10','h-10'] : ''
            ,lg ? ['w-12','h-12'] : ''
            ,xl ? ['w-14','h-14'] : ''
            ,xxl ? ['w-16','h-16'] : ''
            ,!user?.profile_photo && !user?.remembrance_photo && !profilePhoto ? ['p-1'] : ''
            ,passedAwayOverlay && (user?.profile_type === 'immortalized' || user?.passed_away) ? ['grayscale'] : []]"
             @error="avatarImageErrorFallback"
        />
        <div class="flex flex-col justify-start items-start">
            <div v-if="!hideName" class="text-gray-900 font-lora font-semibold"
                 :class="{
                    'text-xs': sm,
                    'text-sm': md || lg,
                    'text-lg': xl,
                 }"
            >
                {{user?.full_name ? user?.full_name : (user?.first_name + ' ' + user?.last_name)}}
                <span v-if="date" class="text-slate-600 text-xs font-normal leading-none ml-2">
                    {{date}}
                </span>
            </div>
            <div v-if="showLocation" class="text-teal-500 font-normal"
                 :class="[sm ? ['text-xxs'] : ''
                ,md ? ['text-sm'] : ''
                ,lg ? ['text-sm'] : ''
                ,xl ? ['text-base'] : '']"
            >
                {{user?.location}}
            </div>
            <div v-if="comment?.body || email" class="text-slate-600 font-normal"
                 :class="[sm ? ['text-xxs'] : ''
                ,md ? ['text-sm'] : ''
                ,lg ? ['text-sm'] : ''
                ,xl ? ['text-base'] : '']"
            >
                <div v-if="comment?.body">
                    <p>{{comment.body}}</p>
                    <div class="flex items-end gap-2">
                        <div class="py-2 grid grid-cols-2 gap-2">
                        <img class="h-32 w-32 object-cover rounded-md" v-for="file in comment.files" :src="file.href" :alt="file.name" />
                    </div>
                    <p class="cursor-pointer" @click="() => handleClickViewMoreImages(comment)" v-if="comment.nr_of_remaining_files > 0">{{ `+${comment.nr_of_remaining_files} more` }}</p>
                    </div>
                </div>
                <template v-else>{{ email }}</template>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
